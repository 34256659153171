// import React, { useState } from 'react';
// import axios from 'axios';
//
// // Імпортуємо компоненти Material-UI
// import {
//   Container,
//   Typography,
//   TextField,
//   Button,
//   Box,
//   Paper,
//   Alert,
//   CircularProgress,
// } from '@mui/material';
//
// import { styled } from '@mui/material/styles';
//
// function App() {
//   const [msgNumber, setMsgNumber] = useState('');
//   const [platoNumber, setPlatoNumber] = useState('');
//   const [data, setData] = useState(null);
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setData(null);
//     setLoading(true);
//
//     try {
//       const response = await axios.post('http://localhost:3000/api/getData', {
//         msgNumber,
//         platoNumber,
//       });
//
//       setData(response.data);
//     } catch (err) {
//       setError(err.response?.data?.error || 'Сталася помилка.');
//     } finally {
//       setLoading(false);
//     }
//   };
//
//   return (
//       <Container maxWidth="md">
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h4" align="center" gutterBottom>
//             Перевірка правопорушення
//           </Typography>
//
//           <Paper sx={{ p: 4 }}>
//             <form onSubmit={handleSubmit}>
//               <TextField
//                   label="Серія та номер повідомлення"
//                   variant="outlined"
//                   fullWidth
//                   margin="normal"
//                   value={msgNumber}
//                   onChange={(e) => setMsgNumber(e.target.value)}
//                   required
//               />
//               <TextField
//                   label="Номер автомобіля"
//                   variant="outlined"
//                   fullWidth
//                   margin="normal"
//                   value={platoNumber}
//                   onChange={(e) => setPlatoNumber(e.target.value)}
//                   required
//               />
//               <Button
//                   type="submit"
//                   variant="contained"
//                   color="primary"
//                   fullWidth
//                   sx={{ mt: 2 }}
//                   disabled={loading}
//               >
//                 {loading ? <CircularProgress size={24} color="inherit" /> : 'Перевірити'}
//               </Button>
//             </form>
//           </Paper>
//
//           {error && (
//               <Alert severity="error" sx={{ mt: 2 }}>
//                 {error}
//               </Alert>
//           )}
//
//           {data && (
//               <Paper sx={{ p: 4, mt: 4 }}>
//                 <Typography variant="h5" gutterBottom>
//                   Детальна інформація про правопорушення
//                 </Typography>
//                 <Typography>
//                   <strong>Адреса:</strong> {data.address}
//                 </Typography>
//                 <Typography>
//                   <strong>Номер ТЗ:</strong> {data.carNum}
//                 </Typography>
//                 <Typography>
//                   <strong>Марка ТЗ:</strong> {data.carBrand}
//                 </Typography>
//                 <Typography>
//                   <strong>Модель ТЗ:</strong> {data.carModel}
//                 </Typography>
//                 <Typography>
//                   <strong>Стаття КУпАП:</strong> {data.article}
//                 </Typography>
//                 <Typography>
//                   <strong>Частина:</strong> {data.articlePart}
//                 </Typography>
//                 <Typography>
//                   <strong>Пункт ПДР:</strong> {data.clause}
//                 </Typography>
//                 <Typography>
//                   <strong>Сума штрафу:</strong> {data.penaltyAmount} грн
//                 </Typography>
//                 <Typography>
//                   <strong>Фабула:</strong> {data.fabules}
//                 </Typography>
//                 <Typography>
//                   <strong>Відмітка про вручення:</strong>{' '}
//                   {data.acceptanceMark ? 'Так' : 'Ні'}
//                 </Typography>
//
//                 <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
//                   Фотографії
//                 </Typography>
//                 {data.photos && data.photos.length > 0 ? (
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
//                       {data.photos.map((photoUrl, index) => (
//                           <img
//                               key={index}
//                               src={photoUrl}
//                               alt={`Фото ${index + 1}`}
//                               style={{ maxWidth: '100%', width: '100%', height: 'auto' }}
//                           />
//                       ))}
//                     </Box>
//                 ) : (
//                     <Typography>Фотографії відсутні.</Typography>
//                 )}
//               </Paper>
//           )}
//         </Box>
//       </Container>
//   );
// }
//
// export default App;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Details from './components/Details';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/details" element={<Details />} />
        </Routes>
      </Router>
  );
}

export default App;