import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box } from '@mui/material';
import { ReactComponent as Blazon } from '../images/blazon.svg';
import '../css/HomeStyles.css';

function Home() {
    const [msgNumber, setMsgNumber] = useState('');
    const [platoNumber, setPlatoNumber] = useState('');
    const navigate = useNavigate();

    const handleMsgNumberChange = (e) => {
        const input = e.target.value.toUpperCase();
        setMsgNumber(input);
    };

    const transliteratePlatoNumber = (input) => {
        const translitMap = {
            'А': 'A', 'В': 'B', 'Е': 'E', 'І': 'I', 'К': 'K', 'М': 'M',
            'Н': 'H', 'О': 'O', 'Р': 'P', 'С': 'C', 'Т': 'T', 'У': 'Y', 'Х': 'X',
        };
        return input.replace(/[А-Я]/g, (char) => translitMap[char] || char);
    };

    const handlePlatoNumberChange = (e) => {
        const input = e.target.value.toUpperCase();
        const transliterated = transliteratePlatoNumber(input);
        setPlatoNumber(transliterated);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const numericMsgNumber = msgNumber.replace(/\D/g, ''); // Лише цифри для запиту
            const response = await axios.post('https://splata.com.ua/api/getFine', { msgNumber: numericMsgNumber, platoNumber });
            navigate('/details', { state: { data: response.data } });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <Container maxWidth="false" className="container">
            <div className="form-container">
                <div className="icon">
                    <Blazon width={80} height={80} />
                </div>

                {/* Заголовок */}
                <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#333', fontFamily: 'e-UkraineHead' }}>
                    Сплата штрафу онлайн
                </Typography>

                {/* Підзаголовок */}
                <Typography variant="body1" sx={{ mb: 3, color: '#555', fontFamily: 'e-Ukraine' }}>
                    Введіть дані, щоб перевірити наявність штрафу
                </Typography>

                <form onSubmit={handleSubmit}>
                    <div className="input-field">
                        <label htmlFor="msgNumber">Серія та номер постанови</label>
                        <input
                            type="text"
                            id="msgNumber"
                            value={msgNumber}
                            onChange={handleMsgNumberChange}
                            placeholder="Введіть серію та номер"
                            required
                        />
                    </div>

                    <div className="input-field">
                        <label htmlFor="platoNumber">Державний номерний знак</label>
                        <input
                            type="text"
                            id="platoNumber"
                            value={platoNumber}
                            onChange={handlePlatoNumberChange}
                            placeholder="Введіть номер знаку"
                            required
                        />
                    </div>

                    <Box sx={{ mt: 3 }}>
                        <button type="submit" className="gradient-button">
                            Перевірити
                        </button>
                    </Box>
                </form>
            </div>

            {/* Секції на білому фоні */}
            <div className="section-wrapper">
                <div className="section">
                    <div className="section-item">
                        <h3>Зручний перегляд штрафів</h3>
                        <p>Ми надаємо детальну інформацію про всі ваші штрафи в одному місці, включаючи їх статус, дату правопорушення та суму.</p>
                    </div>
                    <div className="section-item">
                        <h3>Мінімальна комісія</h3>
                        <p>Наш сервіс пропонує найнижчу комісію для оплати штрафів, щоб ви не переплачували за адміністративні витрати.</p>
                    </div>
                    <div className="section-item">
                        <h3>Швидка обробка платежів</h3>
                        <p>Оплата штрафу відбувається миттєво, і ми гарантуємо, що гроші надійдуть безпосередньо до державного казначейства.</p>
                    </div>
                    <div className="section-item">
                        <h3>Безпека та надійність</h3>
                        <p>Ваші особисті дані та фінансова інформація захищені сучасними технологіями шифрування, що забезпечує максимальну безпеку транзакцій.</p>
                    </div>
                    <div className="section-item">
                        <h3>Пільговий період</h3>
                        <p>Ми автоматично обчислюємо, чи є ви в пільговому періоді, і пропонуємо знижку 50% на штраф при оплаті в перші 10 днів.</p>
                    </div>
                    <div className="section-item">
                        <h3>Простота використання</h3>
                        <p>Вам не потрібно запам'ятовувати реквізити чи шукати інші способи оплати — весь процес автоматизований і доступний за кілька кліків.</p>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-left">
                        <Blazon width={40} height={40} />
                        <p>Splata</p>
                        <p>Офіційний веб-сайт</p>
                    </div>
                    <div className="footer-right">
                        <p>Потеревніти: <a href="mailto:hello@thedigital.gov.ua">hello@thedigital.gov.ua</a></p>
                        <div className="social-icons">
                            <a href="#"><i className="fab fa-facebook"></i></a>
                            <a href="#"><i className="fab fa-telegram"></i></a>
                            <a href="#"><i className="fab fa-instagram"></i></a>
                            <a href="#"><i className="fab fa-youtube"></i></a>
                            <a href="#"><i className="fab fa-viber"></i></a>
                        </div>
                    </div>
                </div>
            </footer>
        </Container>
    );
}

export default Home;