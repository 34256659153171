import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import axios from 'axios';
import { ReactComponent as Blazon } from '../images/blazon.svg';
import '../css/DetailsStyles.css';

dayjs.extend(customParseFormat);

function getCarLogoUrl(brand) {
    return `https://logo.clearbit.com/${brand?.toLowerCase()}.com?size=80&key=pk_J76S21mCRnOkh5c9ArX_bA`;
}

function Details() {
    const location = useLocation();
    const stateData = location.state?.data;
    const searchParams = new URLSearchParams(location.search);
    const msgNumber = searchParams.get('msgNumber') || stateData?.msg_penalty_number;
    const carNum = searchParams.get('carNum') || stateData?.car_number;

    const [data, setData] = useState(stateData || null);
    const [penaltyAmount, setPenaltyAmount] = useState(parseFloat(stateData?.penalty_amount || 0));
    const [serviceFee, setServiceFee] = useState((penaltyAmount * 0.15).toFixed(2));
    const [totalAmount, setTotalAmount] = useState((penaltyAmount + parseFloat(serviceFee)).toFixed(2));
    const [status, setStatus] = useState("Очікує оплату");
    const [discountActive, setDiscountActive] = useState(false);
    const [daysLeftForDiscount, setDaysLeftForDiscount] = useState(0);
    const violationDate = data?.violation_date ? dayjs(data.violation_date, 'DD MMMM YYYY р.') : null;

    useEffect(() => {
        const fetchData = async () => {
            if (!stateData && msgNumber && carNum) {
                try {
                    const response = await axios.post('https://splata.com.ua/api/getFine', { msgNumber, platoNumber: carNum });
                    setData(response.data);
                } catch (error) {
                    console.error("Error fetching fine details:", error);
                }
            }
        };
        fetchData();
    }, [msgNumber, carNum, stateData]);

    useEffect(() => {
        if (violationDate) {
            const daysPassed = dayjs().diff(violationDate, 'day');
            if (daysPassed <= 10) {
                const discountedPenalty = penaltyAmount * 0.5;
                setPenaltyAmount(discountedPenalty);
                const newServiceFee = (discountedPenalty * 0.15).toFixed(2);
                setServiceFee(newServiceFee);
                setTotalAmount((discountedPenalty + parseFloat(newServiceFee)).toFixed(2));
                setDiscountActive(true);
                setDaysLeftForDiscount(10 - daysPassed);
            } else {
                setDiscountActive(false);
                const newServiceFee = (penaltyAmount * 0.15).toFixed(2);
                setServiceFee(newServiceFee);
                setTotalAmount((penaltyAmount + parseFloat(newServiceFee)).toFixed(2));
            }
        }
    }, [violationDate, penaltyAmount]);

    useEffect(() => {
        if (data?.payment_amount > 0) {
            setStatus("Сплачено");
        }
    }, [data]);

    const handlePenaltyChange = (event) => {
        const newPenaltyAmount = parseFloat(event.target.value) || 0;
        setPenaltyAmount(newPenaltyAmount);
        const newServiceFee = (newPenaltyAmount * 0.15).toFixed(2);
        setServiceFee(newServiceFee);
        setTotalAmount((newPenaltyAmount + parseFloat(newServiceFee)).toFixed(2));
    };

    const handlePayment = async () => {
        try {
            const response = await axios.post('https://splata.com.ua/api/createLiqPayPayment', {
                amount: totalAmount,
                currency: 'UAH',
                description: `Оплата штрафу №${msgNumber}`,
                orderId: `order_${msgNumber}`
            });

            const { data, signature } = response.data;
            const form = document.createElement('form');
            form.setAttribute('method', 'POST');
            form.setAttribute('action', 'https://www.liqpay.ua/api/3/checkout');
            form.setAttribute('accept-charset', 'utf-8');

            const dataInput = document.createElement('input');
            dataInput.setAttribute('type', 'hidden');
            dataInput.setAttribute('name', 'data');
            dataInput.setAttribute('value', data);
            form.appendChild(dataInput);

            const signatureInput = document.createElement('input');
            signatureInput.setAttribute('type', 'hidden');
            signatureInput.setAttribute('name', 'signature');
            signatureInput.setAttribute('value', signature);
            form.appendChild(signatureInput);

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error('Error creating LiqPay payment:', error);
        }
    };

    if (!data) return <p>Завантаження...</p>;

    return (
        <div className="details-container">
            <div className="header-gradient">
                <div className="header-content">
                    <Blazon className="header-logo" width={80} height={80} />
                    <h4 className="header-title">Сплатити штраф за номером постанови</h4>
                </div>
            </div>

            <div className="details-content">
                <div className="details-section">
                    <p><strong>Постанова:</strong> {msgNumber}</p>
                    <p><strong>Номер. знак ТЗ:</strong> {carNum}</p>
                    <div className="car-info">
                        <img src={getCarLogoUrl(data.car_brand)} alt={`${data.car_brand} logo`} width={50} height={50} onError={(e) => e.target.style.display = 'none'} />
                        <p><strong>Марка автомобіля:</strong> {data.car_brand || "Невідомо"}</p>
                    </div>
                    <p><strong>Модель автомобіля:</strong> {data.car_model || "Невідомо"}</p>
                    <p><strong>Місце порушення:</strong> {data.address || "Невідомо"}</p>
                    <p><strong>Дата правопорушення:</strong> {data.violation_date || "Невідомо"}</p>
                    <p><strong>Час правопорушення:</strong> {data.violation_time || "Невідомо"}</p>
                    <p><strong>Статус:</strong> {status}</p>
                    {status === "Сплачено" && (
                        <>
                            <p><strong>Дата сплати:</strong> {data.payment_date || "Невідомо"}</p>
                            <p><strong>Сплачена сума:</strong> {data.payment_amount || "Невідомо"}</p>
                        </>
                    )}
                </div>

                {status !== "Сплачено" && (
                    <>
                        {discountActive && (
                            <p className="details-discount">
                                Пільговий період: залишається {daysLeftForDiscount} днів для оплати зі знижкою.
                            </p>
                        )}

                        <div className="details-grid">
                            <div className="details-input">
                                <label>Email для отримання квитанції</label>
                                <input type="email" placeholder="Введіть email" />
                            </div>
                            <div className="details-input">
                                <label>ПІБ</label>
                                <input type="text" placeholder="Введіть ПІБ" />
                            </div>
                        </div>

                        <div className="details-payment-section">
                            <h2 className="details-subtitle">До сплати</h2>
                            <div className="details-payment-grid">
                                <div className="details-input">
                                    <label>Сума штрафу</label>
                                    <input
                                        type="number"
                                        value={penaltyAmount}
                                        onChange={handlePenaltyChange}
                                        step="0.01"
                                        className="details-input"
                                    />
                                </div>
                                <div className="details-payment-item">
                                    <p>Плата за сервіс</p>
                                    <p>{serviceFee} ₴</p>
                                </div>
                                <div className="details-payment-total">
                                    <h3>{totalAmount} ₴</h3>
                                    <p>Без урахування комісії банку</p>
                                </div>
                            </div>
                        </div>

                        <button className="details-button" onClick={handlePayment}>Сплатити</button>
                        <p className="details-agreement">Натискаючи на кнопку “Сплатити”, ви приймаєте <a href="/">Угоду користувача</a>.</p>
                    </>
                )}

                <h2 className="details-heading">Інформація про правопорушення</h2>
                <p className="details-text"><strong>Правопорушення:</strong> {data.fabules || "Невідомо"}</p>

                <h2 className="details-heading">Фотографії:</h2>
                <div className="details-photo-grid">
                    {data.photos && data.photos.length > 0 ? (
                        data.photos.map((photoUrl, index) => (
                            <img key={index} src={photoUrl} alt={`Photo ${index + 1}`} className="details-photo" />
                        ))
                    ) : (
                        <p className="details-text">Фотографії не знайдено.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Details;