// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
//
// import { ThemeProvider, createTheme } from '@mui/material/styles';
//
// const theme = createTheme(); // Ви можете налаштувати тему за бажанням
//
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//         <ThemeProvider theme={theme}>
//             <App />
//         </ThemeProvider>
//     </React.StrictMode>
// );

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);